import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router/index.js'
import * as jQuery from 'jquery'
import $ from 'jquery'

// plugins
import i18n from '@/plugins/i18n'
import i18nFunc from '@/plugins/i18n_func'

Vue.use(i18nFunc, {
  i18n
})

window['jQuery'] = jQuery

new Vue({
  render: h => h(App),
  router,
  i18n,
  $
}).$mount('#app')
