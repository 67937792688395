// i18n method
export default {
  install (Vue, options) {
    const { i18n } = options
    
    // locale에 맞는 이미지 변환 
    Vue.prototype.$_gChange_image = (image) => {
      // const spTxt = path.split('')
      // let symbol 
      // if (spTxt[spTxt.length-1] === '/') {
      //   symbol = require('@/assets' + path)
      // } else {
      //   symbol = require('@/assets/' + path)
      // }
      // return symbol
      return require('@/assets/images/' + (i18n.locale === 'ko' ? '' : (i18n.locale + '/')) + image)
    },

    // 변경된 locale 세팅
    Vue.prototype.$_gChange_Locale = (value) => {
      if (value.trim() === 'en' || value.trim() === 'ko') {
        i18n.locale = value
        localStorage.setItem('locale', i18n.locale)
      }
    }

    // return locale
    Vue.prototype.$_gGet_Locale = () => {
      return i18n.locale
    }
  }
}