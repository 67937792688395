<template>
  <div id="app" :class="`${$_gGet_Locale()}`">
    <router-view/>
  </div>
</template>

<script>
import {loadLanguageAsync} from '@/plugins/i18n.js'
import instance from '@/assets/js/api.js'

export default {
  name: "App",  
  created() {
    loadLanguageAsync();

    // post refferrer
    const url = '/referrer'
    const { referrer } = document;
    let data = new FormData()

    if (referrer) {
      const splitIdx = referrer.indexOf('?');
      if (splitIdx < 0) data.append('referrer', referrer)
      else {
        data.append('referrer', referrer.slice(0, splitIdx))
        data.append('referrerParam', referrer.slice(splitIdx + 1));
      }
    }
    data.append('isReferrer', referrer ? true : false);
    instance.post(url, data);
  },
}
</script>

<style>
</style>

<style>
@import 'App.css';
</style>
