import axios from 'axios'
import router from '@/router/index.js'
// https://velog.io/@skyepodium/axios-%EC%9D%B8%ED%84%B0%EC%85%89%ED%84%B0%EB%A1%9C-API-%EA%B4%80%EB%A6%AC%ED%95%98%EA%B8%B0

const baseAPIURL = process.env.VUE_APP_ACRYL_BASE_URL + '/api/v1/acryl'

const instance = axios.create({
  baseURL: baseAPIURL,
});

instance.interceptors.request.use(
  function (config) {
    return config
  },
  function (error) {
    router.push({name: '500error'});
    
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  function (response) {    
    return response.data
  },
  function (error) {
    router.push({name: '500error'});

    return Promise.reject(error)
  }
)

export default instance;