const routes = [
  {
    path: '*',
    redirect: {
      name: '400error'
    }
  },
  {
    name: 'kosmi2021',
    path: '/kosmi2021',
    redirect: {
      name: 'kosmi2021'
    }
  },
  {
    name: '400error',
    path: '/error_400',
    component: () => import('@/pages/400ErrorPage.vue')
  },
  {
    name: '500error',
    path: '/error',
    component: () => import('@/pages/500ErrorPage.vue')
  },
  {
    path: '/',
    component: () => import('@/pages/AcrylPage.vue'),
    children: [
      {
        path: '',
        name: 'main',
        component: () => import('@/views/MainView.vue')
      },
      {
        path: 'acryl',
        name: 'acryl',
        component: () => import('@/views/AcrylView.vue'),
        props: true
      },
      {
        path: 'products',
        name: 'products',
        component: () => import('@/views/ServiceView.vue')
      },
      {
        path: 'news',
        name: 'news',
        component: () => import('@/views/NewsView.vue')
      },
      {
        path: 'newsView',
        name: 'newsView',
        component: () => import('@/views/NewsDetailView.vue'),
      },
      {
        path: 'partners',
        name: 'partners',
        component: () => import('@/views/PartnersView.vue')
      },
      {
        path: 'recruitment',
        name: 'recruitment',
        component: () => import('@/views/CareerView.vue')
      },
      {
        path: 'wellcare',
        name: 'wellcare',
        component: () => import('@/views/WellcareView.vue')
      },
      {
        path: 'business',
        name: 'business',
        component: () => import('@/views/BusinessView.vue')
      }
    ]
  },
]

export default routes
