import Vue from 'vue'
import Router from 'vue-router'
import routes from '@/router/routes.js'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes,
  // https://router.vuejs.org/kr/guide/advanced/scroll-behavior.html
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

export default router
