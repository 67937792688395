import Vue from 'vue'
import VueI18n from 'vue-i18n'
import instance from '@/assets/js/api.js'
import { locale } from 'core-js'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: initLocale(),
  fallbackLocale: initLocale(),
  silentTranslationWarn: true,
  messages: loadLocaleMessages()
})

function initLocale () {
  const { search, href } = document.location;

  if (search) {
    if (search.indexOf('en') > 0) localStorage.setItem('locale', 'en');
    else localStorage.setItem('locale', 'ko');
    document.location.href = href.split(search)[0];
  }
  if (localStorage.getItem('locale')) return localStorage.getItem('locale');

  let locale = navigator.language || navigator.userLanguage
  locale = locale.substring(0, 2)
  if (locale !== 'ko') locale = 'en'
  return locale
}

function loadLocaleMessages () {
  const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

export const loadLanguageAsync = () => {
  return new Promise((resolve) => {
        const localKo = require("../locales/ko.json");
        const localEn = require("../locales/en.json");

        // i18n.setLocaleMessage('ko', data['korJSON'])
        // i18n.setLocaleMessage('en', data['engJSON'])
        i18n.setLocaleMessage('ko', localKo)
        i18n.setLocaleMessage('en', localEn)

        resolve();
    // let data = instance({
    //   url: '/locale',
    //   method: 'get',
    // })
    // data.then(responseData => {
    //   const {
    //     result,
    //     response
    //   } = responseData

    //   if (result) {
    //     const {
    //       data,
    //     } = response

    //     const localKo = require("../locales/ko.json");
    //     const localEn = require("../locales/en.json");

    //     // i18n.setLocaleMessage('ko', data['korJSON'])
    //     // i18n.setLocaleMessage('en', data['engJSON'])
    //     i18n.setLocaleMessage('ko', localKo)
    //     i18n.setLocaleMessage('en', localEn)

    //     resolve()
    //   }
    // })
  });
}

export default i18n